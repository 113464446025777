import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/layout-mdx.jsx";
import Instructeurs from '../components/instructeurs';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Instructeurs image="Maitre-TRAN-Hoai-Ngoc.jpg" mdxType="Instructeurs">
      <p className="text-muted lead" style={{
        marginBottom: 0
      }}><small>Fondateur</small></p>
      <h2>{`Vo-Su TRAN Hoai-Ngoc`}</h2>
      <ul>
        <li parentName="ul">{`Né en 1957`}</li>
        <li parentName="ul">{`Pratique les arts martiaux depuis l’âge de 6 ans`}</li>
        <li parentName="ul">{`Fondateur de l’école Cuu-Long Vo-Dao de France (1981)`}</li>
        <li parentName="ul">{`Praticien et formateur en M.T.C.`}</li>
        <li parentName="ul">{`Professeur de QI-Gong 8ème DUAN Diplômé d’État`}</li>
        <li parentName="ul">{`6`}<sup>{`ème`}</sup>{` de l’Union Française Vo-Dao Vietnam.`}</li>
        <li parentName="ul">{`5`}<sup>{`ème`}</sup>{` Duan de Kung-fu de la Fédération Arts Énergétiques Martiaux Chinois`}</li>
        <li parentName="ul">{`Diplôme Universitaire “Santé et bien-être dans la Pensée traditionnelle Chinois”`}</li>
        <li parentName="ul">{`Enseigné à l’Université de Nice-Sophia Antipolis en collaboration avec le Pr HU Weiguo
qui était Directeur de recherches à l’Académie de M.T.C. de Pékin.`}</li>
      </ul>
    </Instructeurs>
    <Instructeurs image="david-tran.jpg" mdxType="Instructeurs">
      <p className="text-muted lead" style={{
        marginBottom: 0
      }}><small>Responsable technique de l’AM9D</small></p>
      <h2>{`Vo-Su TRAN David`}</h2>
      <p>{`Successeur de la 3`}<sup>{`ème`}</sup>{` génération du Cuu-Long Vo-Dao`}<br parentName="p"></br>{`
`}{`Pratique les arts martiaux depuis l’âge de 4 ans`}</p>
      <p><strong parentName="p">{`Formations :`}</strong>{` `}</p>
      <ul>
        <li parentName="ul">{`Vo-co-truyen depuis 1981`}</li>
        <li parentName="ul">{`Close-combat (2000-2008)`}</li>
        <li parentName="ul">{`Jeet-Kune-Do (2000-2012)`}</li>
        <li parentName="ul">{`Vinh-Xuan (2007-2015)`}</li>
        <li parentName="ul">{`Boxe Thaïlandaise (2006-2012)`}</li>
        <li parentName="ul">{`Grappling (2008-2012)`}</li>
        <li parentName="ul">{`Kali-Eskrima Sinawali depuis 2015`}</li>
        <li parentName="ul">{`Sabre-laser (2015-2019)`}</li>
        <li parentName="ul">{`FCS Kali-Eskrima depuis 2017`}</li>
      </ul>
      <p><strong parentName="p">{`Diplômes et grades :`}</strong></p>
      <ul>
        <li parentName="ul">{`Professeur d’Arts Martiaux DEJEPS spécialité Perfectionnement Sportif, mention Karaté et Disciplines Associées`}</li>
        <li parentName="ul">{`3`}<sup>{`ème`}</sup>{` Dang de l’Union Française Vo-Dao Vietnam`}</li>
        <li parentName="ul">{`4`}<sup>{`ème`}</sup>{` Dan officiel F.F.K.D.A.`}</li>
      </ul>
      <p><strong parentName="p">{`Titres :`}</strong></p>
      <ul>
        <li parentName="ul">{`Plusieurs fois champion de France en technique mains nues et armes (1983-2011)`}</li>
      </ul>
      <p><strong parentName="p">{`Fonctions :`}</strong></p>
      <ul>
        <li parentName="ul">{`Ex-professeur en technique d’intervention pour la Police de Sarcelles (95)`}</li>
        <li parentName="ul">{`Ex-formateur en self-défense pour les adjoints de sécurité de Nice (06)`}</li>
        <li parentName="ul">{`Ex-élu du Comité Départementale de la PACA (06)`}</li>
        <li parentName="ul">{`Responsable Technique du Vo-Co-Truyen C.L.V.D.`}</li>
        <li parentName="ul">{`Référent régional FCS Kali-Eskrima`}</li>
      </ul>
    </Instructeurs>
    <Instructeurs image="anael-beets.jpg" mdxType="Instructeurs">
      <p className="text-muted lead" style={{
        marginBottom: 0
      }}><small>Instructeur en boxe pied</small></p>
      <h2>{`BEETS Anaël`}</h2>
      <ul>
        <li parentName="ul">{`Pratique les arts martiaux et sports de combat depuis l’âge de 10 ans`}</li>
        <li parentName="ul">{`Pratique du Taekwondo compétitif pendant 10 ans`}</li>
        <li parentName="ul">{`Multiple champion régional`}</li>
        <li parentName="ul">{`Médaillé national et international`}</li>
        <li parentName="ul">{`Puis pratique divers arts et sports les 10 années suivante, notamment Aïkido, Karaté, boxe, full contact, sabre, etc.`}</li>
        <li parentName="ul">{`2`}<sup>{`ème`}</sup>{` Dan Taekwondo`}</li>
        <li parentName="ul">{`Détenteur du DIF, CQP APAM`}</li>
      </ul>
    </Instructeurs>
    <Instructeurs image="anthony-lococo.jpg" mdxType="Instructeurs">
      <p className="text-muted lead" style={{
        marginBottom: 0
      }}><small>Instructeur en grappling ainsi qu’en Sabre-laser FFE</small></p>
      <h2>{`LOCOCO Anthony`}</h2>
      <ul>
        <li parentName="ul">{`BPJEPS APT UCPA 2015`}</li>
        <li parentName="ul">{`Brevet fédéral FF sport pour tous équilibre dynamique`}</li>
        <li parentName="ul">{`Instructeur niv 1 SL FFE`}</li>
        <li parentName="ul">{`Instructeur niv 2 SL FFE en cours`}</li>
        <li parentName="ul">{`Référent régional équipe technique Sabre-Laser PACA-Corse`}</li>
      </ul>
      <p><strong parentName="p">{`Pratique :`}</strong></p>
      <ul>
        <li parentName="ul">{`Shorinji Kempo : 5 ans`}</li>
        <li parentName="ul">{`Taekwondo : 6 ans`}</li>
        <li parentName="ul">{`Karaté : 2 ans`}</li>
        <li parentName="ul">{`Katori Shinto Ryu : 1 an`}</li>
        <li parentName="ul">{`Kendo : 2 ans`}</li>
        <li parentName="ul">{`Aïkido : 2 ans`}</li>
      </ul>
      <p><strong parentName="p">{`Titres :`}</strong></p>
      <ul>
        <li parentName="ul">{`2 fois champions tournoi Sabre-laser FFE`}</li>
        <li parentName="ul">{`1 fois médaille d'argent FFE`}</li>
      </ul>
    </Instructeurs>
    <Instructeurs image="julien-griffaud.jpg" mdxType="Instructeurs">
      <p className="text-muted lead" style={{
        marginBottom: 0
      }}><small>Instructeur en Free-Fight</small></p>
      <h2>{`GRIFFAUD Julien`}</h2>
      <ul>
        <li parentName="ul">{`Ceinture noire 1`}<sup>{`er`}</sup>{` DUAN en Kung-FU Traditionnel (2010)`}</li>
        <li parentName="ul">{`Détenteur du DIF FFKDA (2015)`}</li>
      </ul>
      <p><strong parentName="p">{`Pratique :`}</strong>{` `}</p>
      <ul>
        <li parentName="ul">{`Kung-fu traditionnel : 10 ans `}</li>
        <li parentName="ul">{`Sanda (boxe chinoise) : 8 ans`}</li>
        <li parentName="ul">{`MMA : 7 ans `}</li>
        <li parentName="ul">{`Boxe Thaïlandaise : 5 ans`}</li>
        <li parentName="ul">{`Kali : 1 an`}</li>
        <li parentName="ul">{`Jeet Kune Do : 1 an `}</li>
      </ul>
    </Instructeurs>
    <Instructeurs image="" mdxType="Instructeurs">
      <p className="text-muted lead" style={{
        marginBottom: 0
      }}><small>Instructeur de Vo-Co-Truyen</small></p>
      <h2>{`MARRAS Jean-François`}</h2>
      <ul>
        <li parentName="ul">{`Ceinture noire 1`}<sup>{`er`}</sup>{` Dan FFK`}</li>
        <li parentName="ul">{`Pratique les arts martiaux depuis l’âge de 12 ans`}</li>
      </ul>
      <p><strong parentName="p">{`Formation :`}</strong></p>
      <ul>
        <li parentName="ul">{`Judo/Jiu-Jitsu (1994-1999)`}</li>
        <li parentName="ul">{`Boxe française`}</li>
        <li parentName="ul">{`Boxe Thaïlandaise`}</li>
        <li parentName="ul">{`Karaté Goju-Ryu`}</li>
        <li parentName="ul">{`Vo-Co-Truyen Cuu-Long Vo-Dao depuis 2000`}</li>
      </ul>
      <p><strong parentName="p">{`Palmarès :`}</strong></p>
      <ul>
        <li parentName="ul">{`Vice-champion de France technique 2002`}</li>
        <li parentName="ul">{`Champion de France combat moins de ceinture noire 2002`}</li>
      </ul>
    </Instructeurs>
    <Instructeurs image="" mdxType="Instructeurs">
      <p className="text-muted lead" style={{
        marginBottom: 0
      }}><small>Instructeur de Jeet-Kune-Do</small></p>
      <h2>{`MELIADO Jean-Pierre`}</h2>
      <p><strong parentName="p">{`Formations et pratiques :`}</strong></p>
      <ul>
        <li parentName="ul">{`Kung-Fu traditionnel Vo-Vi-Nam (1986-1989)`}</li>
        <li parentName="ul">{`Qwan-Ki-Do (1989-1992)`}</li>
        <li parentName="ul">{`Wu-Shu, Sanda boxe-chinoise (1992-1996)`}</li>
        <li parentName="ul">{`Full-Contact boxe américaine (1996-1999)`}</li>
        <li parentName="ul">{`Wu-Shu, Sanda boxe-chinoise (1999-2001)`}</li>
        <li parentName="ul">{`Jeet-Kune-Do, Kali, Jung Fan Kick-Boxing (2002-2013)`}</li>
        <li parentName="ul">{`Penchak Silat depuis 2013`}</li>
        <li parentName="ul">{`Bela Diri Silat Système depuis 2016`}</li>
      </ul>
    </Instructeurs>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      